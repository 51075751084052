<template>
  <div>
    <div class="text-center" v-show="loading">
      <b-spinner variant="primary" label="Spinning"></b-spinner>
    </div>
    <v-toolbar flat dense>
      <v-spacer></v-spacer>
      <v-toolbar-title><span> Ders Programım</span>
        <v-spacer></v-spacer>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="exportLessonProgram" :loading="loading" text small color="primary">
        <v-icon small class="mr-1">mdi-arrow-down</v-icon>
        İndir
      </v-btn>
    </v-toolbar>
    <v-calendar
        ref="calendar"
        :now="today"
        v-model="today"
        :events="events"
        color="primary"
        type="week"
        :weekdays="calendarWeekdays"
        :first-interval="firstInterval"
        :interval-count="intervalCount"
        interval-height="82"
        :short-intervals="false"
        @change="updateRange"
        @click:event="showEvent"
        locale="tr"
    >
      <template v-slot:event="{ event }">
        <v-badge dot v-if="event.description && event.description.length > 0"
        >
          <div class="pl-1">
            <span class="v-event-summary"
            ><strong>{{ event.name }}</strong> {{ event.start | momentHour }}-{{
                event.end | momentHour
              }}</span
            >
          </div>
        </v-badge
        >
        <div v-else class="pl-1">
          <span class="v-event-summary"
          ><strong>{{ event.name }}</strong> {{ event.start | momentHour }}-{{
              event.end | momentHour
            }}</span
          >
        </div>
      </template>
      <template v-slot:day-label-header="{}">
        <span></span>
      </template>
    </v-calendar>
    <v-menu
        v-model="selectedOpen"
        :close-on-content-click="false"
        :activator="selectedElement"
        offset-y
    >
      <v-card color="grey lighten-4" min-width="250px" flat>
        <v-toolbar :color="selectedEvent.color" dark>
          <v-toolbar-title>{{ selectedEvent.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-card-text>
          <h4>
            {{ selectedEvent.start | momentHour }}-{{ selectedEvent.end | momentHour }}
            <br/>
          </h4>
          <h4 v-html="selectedEvent.eventInfo"></h4>
          <h4 v-html="selectedEvent.teacherCount"></h4>
          <h4 v-html="selectedEvent.teachers"></h4>
          <p v-html="selectedEvent.description"></p>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="error" @click="selectedOpen = false">Kapat</v-btn>
          <v-spacer></v-spacer>
          <v-btn class="mr-1" v-if="selectedEvent.link && selectedEvent.link.length > 0" @click.stop="goToLesson(selectedEvent)" fab small color="primary">
            <v-icon>mdi-video</v-icon>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-menu>
    <v-dialog ref="dialog" v-model="dateDialog" width="290px">
      <v-card>
        <v-date-picker
            v-model="StartDate"
            scrollable
            first-day-of-week="1"
            color="green lighten-1"
            header-color="primary"
            locale="tr"
            :allowedDates="allowedDates"
            @change="dateDialog = false"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateDialog = false"> İPTAL</v-btn>
        </v-date-picker>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ApiService from '@/core/services/api.service.js'
import moment from 'moment'
import FileDownload from "js-file-download";

export default {
  name: 'calendar-component',
  props: ['dates'],
  data() {
    return {
      search: '',
      totalRows: 0,
      rows: [],
      loading: true,
      options: {sortBy: ['StartDate'], sortDesc: [false]},
      // today: new Date(),
      // StartDate: new Date().toISOString().slice(0, 10),
      events: [],
      personnelColor: '#38c7d1',
      items: [],
      start: null,
      end: null,
      title: '',
      selectedEvent: {
        eventInfo: null,
        lessonInfo: null,
        teacherCount: null,
        teachers: null,
        description: null,
        name: null
      },
      selectedElement: null,
      selectedOpen: false,
      startWeek: '',
      endWeek: '',
      filterToggle: false,
      calendarWeekdays: [1, 2, 3, 4, 5, 6, 0],
      firstInterval: 8,
      intervalCount: 10,
      printBody: '',
      dateDialog: false,
      allowedDates: (val) => new Date(val).getDay() === 1
    }
  },
  computed: {
    today() {
      return new Date(this.dates[0])
    }
  },
  filters: {
    moment: function (date) {
      return moment(date).locale('tr').format('DD.MM.YYYY ddd HH:mm')
    },
    momentHour: function (date) {
      return moment(date).format('HH:mm')
    }
  },
  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
    options: {
      handler() {
        this.getDataFromApi()
      },
      deep: true
    },
    dates: {
      handler() {
        this.today = new Date(this.dates[0])
      },
      deep: true
    }
  },
  methods: {
    exportLessonProgram() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/StudentLessonTime/exportpdf', {
            Query: {
              StartDate: this.dates[0],
              EndDate: this.dates[1]
            }
          }
      )
          .then((data) => {
            fetch(data.data)
                .then((response) => response.blob())
                .then((blob) => {
                  FileDownload(
                      blob,
                      'Ders Programı.jpg',
                      'image/jpeg'
                  )
                });
            this.loading = false
          })
          .catch(({response}) => {
            ApiService.showError(response)
            this.loading = false
          })
    },

    getDataFromApi() {
      this.loading = true
      ApiService.setHeader()
      ApiService.post('api/StudentLessonTime', {
        sortBy: this.options.sortBy,
        descending: this.options.sortDesc,
        rowsPerPage: -1,
        page: this.options.page ?? 1,
        Query: {
          StartDate: this.dates[0],
          EndDate: this.dates[1]
        }
      })
          .then((data) => {
            this.rows = data.data.Results
            this.totalRows = data.data.TotalNumberOfRecords
            this.loading = false

            //   let hasSaturday = false
            //   let hasSunday = false
            let minHour = 8
            let maxHour = 18
            for (let index = 0; index < this.rows.length; index++) {
              const lt = new Date(this.rows[index].EndDate)
              //   if (lt.getDay() == 6) hasSaturday = true
              //   if (lt.getDay() == 0) hasSunday = true
              if (lt.getHours() < minHour) minHour = lt.getHours()
              if (lt.getHours() > maxHour) maxHour = lt.getHours() + 1
            }
            this.firstInterval = minHour
            this.intervalCount = maxHour - minHour + 1
            //   if (hasSaturday && hasSunday) {
            //     this.calendarWeekdays = [1, 2, 3, 4, 5, 6, 0]
            //   } else if (hasSaturday || hasSunday) {
            //     this.calendarWeekdays = [1, 2, 3, 4, 5, 6]
            //   } else {
            //     this.calendarWeekdays = [1, 2, 3, 4, 5]
            //   }
            this.events = this.rows.map(function (r) {
              return {
                name:
                    r.Period +
                    '. ' +
                    r.CourseName +
                    ' ' +
                    (r.Personnels.length > 0
                        ? r.Personnels[0].FirstName + ' ' + r.Personnels[0].LastName
                        : 'öğretmensiz'),
                start: r.StartDate,
                end: r.EndDate,
                color: r.Personnels.length > 0 ? r.Personnels[0].Color ?? '#38c7d1' : 'red',
                eventInfo: r.ClassBranches + ' - ' + r.CourseName,
                teacherCount:
                    r.Personnels.length != 1
                        ? 'Derse ' + r.Personnels.length + ' öğretmen atanmıştır'
                        : '',
                teachers:
                    r.Personnels.length > 0
                        ? r.Personnels.map(function (p) {
                          return p.FirstName + ' ' + p.LastName
                        }).join(',')
                        : 'yok',
                link: r.Personnels.length > 0 ? r.Personnels[0].MeetingLink : '',
                description: r.Description,
                lessonTimeId: r.Id
              }
            })
          })
          .catch(({response}) => {
            ApiService.showError(response)
          })
    }
    ,
    updateRange({start, end}) {
      this.start = start
      this.end = end
      this.startWeek = this.start.date
      this.endWeek = this.end.date

      this.getDataFromApi()

      let yr = this.start.year
      let mn = this.start.month
      let weekstart = this.start.date.slice(-2)
      let weekend = this.end.date.slice(-2)

      const mNames = [
        'OCAK',
        'ŞUBAT',
        'MART',
        'NİSAN',
        'MAYIS',
        'HAZİRAN',
        'TEMMUZ',
        'AĞUSTOS',
        'EYLÜL',
        'EKİM',
        'KASIM',
        'ARALIK'
      ]
      this.title = weekstart + '-' + weekend + ' ' + mNames[mn - 1] + ' ' + yr
    }
    ,

    showEvent({nativeEvent, event}) {
      const open = () => {
        this.selectedEvent = event
        this.selectedElement = nativeEvent.target
        requestAnimationFrame(() => requestAnimationFrame(() => (this.selectedOpen = true)))
      }

      if (this.selectedOpen) {
        this.selectedOpen = false
        requestAnimationFrame(() => requestAnimationFrame(() => open()))
      } else {
        open()
      }
      nativeEvent.stopPropagation()
    }
    ,
    previousWeek() {
      this.$refs.calendar.prev()
    }
    ,
    nextWeek() {
      this.$refs.calendar.next()
    },

    print() {
      this.printBody =
          '<button type="button" class="btn btn-light-warning font-weight-bold" id="kt_sweetalert_demo_3_1">Warning</button>'
      this.$nextTick(() => {
        this.$htmlToPaper('printBody')
      })
    },

    goToLesson(item) {
      window.open(item.link, '_blank')

      //tıklamayı kaydet
      ApiService.setHeader()
      ApiService.put('api/Attendance/update-attendance', {
        LessonTimeId: item.lessonTimeId,
        MeetingLink: item.link
      })
    }
  }
}
</script>
<style scoped>
::v-deep .v-calendar-daily__intervals-body {
  width: 45px !important;
}

::v-deep .v-calendar-daily__intervals-head {
  width: 45px !important;
}

::v-deep .v-calendar-daily_head-day {
  width: 120px !important;
}

::v-deep .v-calendar-daily__day {
  width: 120px !important;
}

::v-deep .v-calendar-daily__head {
  width: unset !important;
  margin: auto !important;
}

::v-deep .v-calendar-daily {
  overflow: auto !important;
}

::v-deep .v-calendar-daily__body {
  overflow: hidden !important;
  width: unset !important;
  margin: auto;
}

::v-deep .v-calendar-daily__scroll-area {
  overflow: unset !important;
  width: auto !important;
}

::v-deep .v-calendar-daily__pane {
  overflow: hidden !important;
  width: unset !important;
}

::v-deep .v-calendar-daily__day-container {
  width: unset !important;
}

::v-deep .v-event-summary {
  white-space: pre-wrap !important;
}
</style>
