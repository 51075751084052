<template>
  <v-toolbar flat rounded>
    <v-chip
      class="px-1"
      @click="previousWeek"
      label
      outlined
      color="primary"
      :small="$vuetify.breakpoint.mobile"
    >
      <v-icon class="mr-0"> mdi-chevron-left</v-icon>
      <span v-if="!$vuetify.breakpoint.mobile" class="mr-1">ÖNCEKİ HAFTA </span>
    </v-chip>
    <v-spacer></v-spacer>
    <v-toolbar-title class="d-flex align-center">
      <h5 class="mb-0" v-if="$vuetify.breakpoint.mobile && !loading">
        {{ dates[0] | momentDate }} - {{ dates[1] | momentDate }}
      </h5>

      <h3 class="mb-0" v-else-if="!loading">
        {{ dates[0] | momentDate }} - {{ dates[1] | momentDate }}
      </h3>
      <v-btn v-if="!$vuetify.breakpoint.mobile" class="float-right ml-1 takvim" icon small @click="dateDialog = true">
        <v-icon> mdi-calendar</v-icon>
      </v-btn>
    </v-toolbar-title>
    <v-spacer></v-spacer>
      <v-btn v-if="!$vuetify.breakpoint.mobile" class="mr-2" small outlined color="primary" :to="{name: 'odev-planlama'}">Ödev Planlama <v-icon small class="ml-1"
      >mdi-clipboard-text-clock-outline</v-icon> </v-btn>
    <v-btn
        v-else-if="$route.name != 'ders-programi'"
        class="mr-2"
        :to="{ name: 'odevler' }"
        icon
        small
    >
      <v-icon small> fas fa-sort-amount-down</v-icon>
    </v-btn>
    <v-chip
      class="px-1"
      @click="nextWeek"
      label
      outlined
      color="primary"
      :small="$vuetify.breakpoint.mobile"
    >
      <span v-if="!$vuetify.breakpoint.mobile" class="ml-1"> SONRAKİ&nbsp;HAFTA</span>
      <v-icon class="ml-0"> mdi-chevron-right</v-icon>
    </v-chip>
    <v-dialog ref="dialog" v-model="dateDialog" width="290px">
      <v-card>
        <v-date-picker
          v-model="dates[0]"
          scrollable
          first-day-of-week="1"
          color="green lighten-1"
          header-color="primary"
          locale="tr"
          :allowedDates="allowedDates"
          @change="update"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="dateDialog = false"> İPTAL</v-btn>
        </v-date-picker>
      </v-card>
    </v-dialog>
  </v-toolbar>
</template>

<script>
import moment from 'moment'

export default {
  name: 'DashboardDateWidget',
  data() {
    return {
      dates: [],
      dateDialog: false,
      loading: true,
      allowedDates: (val) => new Date(val).getDay() === 1
    }
  },
  created() {
    var date = this.getMonday(new Date())
    this.dates[0] = date.toISOString().slice(0, 10)
    this.dates[1] = date.addDays(6).toISOString().slice(0, 10)
    this.loading = false
    this.$emit('updateDates', this.dates)
  },
  filters: {
    moment: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM dddd') : ''
    },
    momentDate: function (date) {
      return date ? moment(date).locale('tr').format('Do MMMM') : ''
    }
  },

  methods: {
    getMonday(d) {
      d = new Date(d)
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1) // adjust when day is sunday
      return new Date(d.setDate(diff))
    },

    //TODO: merge methods
    previousWeek() {
      this.loading = true

      // başlangıç tarihine göre haftanın pazartesi gününü bul
      var d = new Date(this.dates[0])
      d = this.getMonday(d.isValid() ? d : new Date())

      d = d.addDays(-7)
      this.dates[0] = d.toISOString().slice(0, 10)
      //pazar gününü bul
      this.dates[1] = d.addDays(6).toISOString().slice(0, 10)
      this.$emit('updateDates', this.dates)

      this.loading = false
    },
    nextWeek() {
      this.loading = true

      // başlangıç tarihine göre haftanın pazartesi gününü bul
      var d = new Date(this.dates[0])
      d = this.getMonday(d.isValid() ? d : new Date())

      d = d.addDays(7)
      this.dates[0] = d.toISOString().slice(0, 10)
      //pazar gününü bul
      this.dates[1] = d.addDays(6).toISOString().slice(0, 10)
      this.$emit('updateDates', this.dates)

      this.loading = false
    },

    update() {
      this.loading = true

      var d = new Date(this.dates[0])
      d = this.getMonday(d.isValid() ? d : new Date())

      this.dates[0] = d.toISOString().slice(0, 10)
      this.dates[1] = d.addDays(6).toISOString().slice(0, 10)

      this.$emit('updateDates', this.dates)
      this.loading = false
      this.dateDialog = false
    }
  }
}
</script>
